"use strict";

function matches(query) {
    return Boolean(window.matchMedia(query).matches);
}

function isMobile() {
    return matches("(max-width: 767px)");
}

function isTablet() {
    return matches("(min-width: 768px) and (max-width: 1024px)");
}

function isPortrait() {
    return matches("(orientation: portrait)");
}

function isLandscape() {
    return !isPortrait();
}

module.exports = {
    matches     : matches,
    isMobile    : isMobile,
    isTablet    : isTablet,
    isPortrait  : isPortrait,
    isLandscape : isLandscape
};
