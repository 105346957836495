"use strict";

let locale = document.documentElement.lang || document.documentElement.getAttribute("lang");

function getLocalePath() {
	return `${locale === "en" ? "" : `/${locale}`}/`;
}

module.exports = {
	getLocale     : locale,
	getLocalePath : getLocalePath
};
