"use strict";

let device = require("./modules/device");
let load   = require("./modules/load");
let locale = require("./modules/locale");

// Browser support handler
function isBrowserSupported() {
    return (
        Modernizr.backgroundsize &&
        Modernizr.classlist &&
        Modernizr.csscalc &&
        Modernizr.csstransforms3d &&
        (Modernizr.flexbox || Modernizr.flexboxlegacy) &&
        Modernizr.fontface &&
        Modernizr.svg
    );
}

if (!isBrowserSupported()) {
    window.location.href = `${locale.getLocalePath()}browser-upgrade`;
}

// Fastclick
let FastClick = require("fastclick/lib/fastclick");

FastClick(document.body);

// Device check
if (device.isMobile()) {
    load.js("/assets/js/mobile.js");
} else {
    load.js("/assets/js/full.js");
}
